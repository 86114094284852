import React from "react";
import ParticlesComponent from "./Particles";
import { about, education, experience, leadership, interest } from "../data/data";
import "../styles/about.css"

function About({isDarkMode}) {
  return (
    <div className="about-container">
        {/* Particles Background */}
        <ParticlesComponent isDarkMode={isDarkMode} id="tsparticles" />

        {/* Content on top of the particles */}
        <div className="about-card" style={{ color: isDarkMode ? '#ffffff' : '#333333', backgroundColor: isDarkMode ? '#000000' : '#ffffff'}}>
            <div className="profile-image-container">
                <img className="profile-image" src={about.imageSrc} alt="profile_image" />
            </div>
            <div className="about-info">
                <h5>{about.info}</h5>

                <h2>Interests</h2>
                <div className="interest-item">
                    <p>{interest.interest}</p>
                </div>
            

                <h2>Education</h2>
                {education.map((edu, index) => (
                    <div key={index} className="education-item">
                        <p><strong>{edu.school}</strong></p>
                        <p>{edu.degree}{" "} | {" "}{edu.duration}</p>
                    </div>
                ))}

                <h2>Experience</h2>
                {experience.map((exp, index) => (
                    <div key={index} className="experience-item">
                        <p><strong>{exp.company}</strong></p>
                        <p>{exp.role}{" "} | {" "}{exp.duration}</p>
                    </div>
                ))}

                <h2>Leadership and organizations</h2>
                {leadership.map((lead, index) => (
                    <div key={index} className="leadership-item">
                        <p><strong>{lead.organization}</strong></p>
                        <p>{lead.role}{" "} | {" "}{lead.duration}</p>
                    </div>
                ))}
            </div>
        </div>
    </div>
  );
}

export default About;
